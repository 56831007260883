<script setup>
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const locale = computed(() => usePage().props.locale);

// Layout
import ImgMedia from '@/Components/Images/ImgMedia.vue';
import PublicLayout from '@/Layouts/PublicLayout.vue';

const { t } = useI18n();

const props = defineProps({
    affiliatedOrganizations: Array,
});
</script>

<template>
    <PublicLayout title="Flexable">
        <div class="container">
            <h2 class="mb-2 font-bold text-center text-white uppercase text-5 lg:text-12">
                {{ t('Organizations') }}
            </h2>
            <p class="mb-8 lg:mb-8 lg:leading-6 items-center text-base font-medium uppercase lg:text-xl text-center">
                {{ $t('Click on your organization to download the app!') }}
            </p>
        </div>
        <div class="container grid md:grid-cols-2 xl:grid-cols-3 gap-8 mb-15">
            <div v-for="organization in affiliatedOrganizations" :key="organization.id">
                <a :href="organization.deeplink" target="_blank" class="">
                    <div class="w-full aspect-w-1 aspect-h-1 rounded-lg bg-white rounded">
                        <ImgMedia
                            :media="organization.media.find((media) => media.collection_name === 'logo')"
                            classes="max-w-[80%] max-h-[80%] inset-0 absolute m-auto"
                        />
                    </div>
                </a>
            </div>
        </div>
    </PublicLayout>
</template>
